@import "~bootstrap/scss/bootstrap";
@import "fonts.scss";

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: MatterWeb;
}

.ModalOverlay,
.AlertOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.3;
  transition: opacity 0.3s ease-in-out;
  z-index: 10;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.Modal,
.Alert {
  z-index: 20;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 10px;
  padding: 2rem;
  overflow: visible;

  background-color: #fff;
  box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.13);

  &.Alert {
    width: 27rem;
    max-width: 95vw;
  }

  &.Modal {
    width: 37rem;
    max-width: 95vw;
  }

  max-height: 70vh;
  overflow-y: scroll;

  h2 {
    font-weight: bold;
    font-size: 1.56rem;
    color: #4b4b4b;
  }

  .BodyElement {
    margin-bottom: 1rem;
  }

  .Body {
    font-size: 0.875rem;
    line-height: 1.1875rem;
    color: #8f8f8f;
    overflow: visible;

    .Meeting {
      .Attribute {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 15px 0;

        .Key {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 10px;
          padding-top: 6px;

          .Label {
            margin-left: 3px;
          }
        }
        .Value {
          flex: 1;
          display: flex;
          flex-direction: column;
          position: relative;

          input {
            align-self: stretch;
            background-color: #f4f4f4;
            border: none !important;
            outline: none !important;
            border-radius: 5px;
            padding: 8px 10px;

            &::placeholder {
              color: #c6c6c6;
            }
          }

          .react-datepicker__input-container input {
            width: 100%;
          }

          .User {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 5px 10px;
            margin-bottom: 5px;

            .Avatar {
              width: 28px;
              height: 28px;
              border-radius: 14px;
              margin-right: 5px;
            }
            span.Name {
              flex: 1;
            }
            button {
              border: none;
              outline: none;
              background: none;
            }
          }

          .UsersResult {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            background: #fff;
            padding: 10px 0;
            border-radius: 5px;
            z-index: 200;
            max-height: 130px;
            overflow-y: scroll;

            .User {
              margin-bottom: 0px;
              cursor: pointer;
              &:hover {
                background-color: var(--theme-primary-color-hover);
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .Footer {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.one {
      justify-content: center;
    }

    button {
      flex: 1;
    }

    button + button {
      flex: 1;
      margin-left: 10px;
    }
  }
}

.btn {
  border: none;
  border-radius: 5px;
  background: var(--theme-primary-color);
  color: #ffffff;
  font-size: 0.8125rem;
  line-height: 1.3125rem;
  padding-top: 7px;
  padding-bottom: 7px;
  font-weight: bold;

  &.btn-block {
    display: block;

    &.btn-lg {
      padding: 1rem 0;
      font-size: 1rem;
    }
  }

  &:hover {
    color: #ffffff;
    background-color: var(--theme-primary-color-hover);
  }

  &:focus {
    outline: none !important;
    box-shadow: none;
  }

  &.btn-outline {
    border: 1px solid var(--theme-primary-color);
    background: transparent;
    color: var(--theme-primary-color);
  }

  // &:disabled {
  //   background: none;
  //   border: 1px solid #e5e5e5;
  //   color: #8d8d8d;
  //   &:hover {
  //     color: #8d8d8d;
  //   }
  // }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background: var(--theme-primary-color);
    }
  }
}

.top-bar {
  background-color: #fff;
  padding: 0.75rem 25px 0.75rem 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 56px;

  .left {
    display: flex;
    flex-direction: row;

    align-items: center;

    .logo {
      width: 2rem;
      height: 2rem;
      border-radius: 0.625rem;
      background-color: #000000;
      overflow: hidden;
      background-size: cover;
      margin-right: 0.625rem;
    }

    h1 {
      font-size: 1rem;
      font-weight: bold;
      color: #4b4b4b;
      margin: 0;
    }
  }

  .right {
    display: flex;
    flex-direction: row;
    align-items: center;

    .basic-thumb {
      margin-right: 0.42rem;
    }

    a {
      margin-right: 1rem;
      font-size: 0.875rem;
      color: #4b4b4b;
      font-weight: normal;

      &.active {
        color: var(--theme-primary-color);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.pill {
  overflow: hidden;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e0e1e3;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
}

.empty-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.message-box {
  display: none;

  border: 1px solid #f81f16;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 0.6875rem;
  color: #f81f16;
  justify-content: center;
  flex-direction: column;

  p {
    margin-bottom: 0;
  }

  &.show {
    display: flex;
  }
}

.login {
  display: flex;
  flex-direction: column;
  .logo {
    height: 9.25rem;
    padding: 1.3rem 4rem;
    margin-bottom: 2rem;
    background-origin: content-box;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    image-rendering: -webkit-optimize-contrast;
  }
  h2 {
    font-size: 1.5625rem;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
  }
  h3 {
    font-size: 1rem;
    font-weight: normal;
    color: #ffffff;
    opacity: 0.7;
    text-align: center;
  }

  .login-form {
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 21.56rem;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0.625rem;

    input {
      padding: 0.9rem 0.375rem;
      margin: 0 0.625rem;
      border: none;
      outline: none;
      border-bottom: 1px solid #e9e9e9;

      &:last-child {
        border: none;
      }
    }
  }

  .btn {
    margin-top: 2rem;
    background-color: rgba(255, 255, 255, 0.3);
  }

  .help {
    display: block;
    font-size: 0.8125rem;
    color: rgba(255, 255, 255, 0.7);

    text-align: center;
    margin-top: 0.6rem;

    &.right {
      text-align: right;
    }

    span {
      opacity: 0.65;
    }
    a {
      color: #fff;
      opacity: 1 !important;
    }
  }
}

.Outmost {
  display: flex;
  flex-direction: row;
  background-color: #eaebf0;
  flex: 1;

  .leftBar {
    margin-top: 25px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #ffffff;
    align-self: flex-start;
    padding: 4px 23px 4px 0;

    .item {
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: center;
      border: none;
      padding: 6px 8px;
      font-size: 0.8125rem;
      color: #8f8f8f;
      text-decoration: none;

      span[class*="icon"] {
        font-size: 1.1rem;
        margin-right: 10px;
      }

      span[class*="icon"]:before {
        color: #8f8f8f;
      }

      span.Badge {
        position: absolute;
        left: 15px;
        top: -3px;
        background-color: #bd1f1f;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 0.6rem;
        width: 16px;
        height: 16px;
        border-radius: 8px;
      }

      &.active {
        color: var(--theme-primary-color);
        span:before {
          color: var(--theme-primary-color);
        }
      }
    }
  }

  .Outer {
    margin: 25px;
    margin-left: 20px;
    display: flex;
    flex: 1;
    flex-direction: row;
    position: relative;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      justify-content: flex-start;
    }

    .curtain {
      border-radius: 10px;
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0px;
      width: 18.75rem;
      overflow: hidden;
      background-color: #fff;
      transition: width 0.3s ease-in-out;
      overflow-y: scroll;

      &.folded {
        width: 0;
      }

      .inner {
        width: 18.75rem;
        padding: 1rem 0;
        display: flex;
        flex-direction: column;

        .top {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;
          margin-left: 1rem;
          margin-right: 1rem;

          h3 {
            font-size: 1.25rem;
            font-weight: bold;
            color: #4b4b4b;
          }

          a {
            font-size: 1.5rem;
            text-decoration: none;
          }
        }

        .daySelector {
          padding: 0 1rem 1rem 1rem;
          margin-right: 1rem;
          display: flex;
          flex-direction: row;
          overflow-x: scroll;
          &::-webkit-scrollbar {
            display: none;
          }

          .day {
            padding: 0.5rem 0.875rem;
            font-size: 0.6875rem;
            border-radius: 5px;
            border: 1px solid #e5e5e5;
            text-transform: uppercase;
            margin-right: 0.44rem;
            color: #8f8f8f;
            text-decoration: none;
            background-color: transparent;

            &.active {
              color: #4b4b4b;
              background-color: var(--theme-primary-color-light);
              border: none;
            }

            &:focus {
              outline: none;
            }

            &:last-child {
              margin-right: 2rem;
            }
          }
        }

        .day {
          .hour {
            .header {
              background-color: #f9f9fb;
              padding: 0.6875rem 1rem;
              font-size: 0.6875rem;
              color: #8f8f8f;
            }
            .appointments {
              padding: 1rem;

              .appointment {
                border: 1px solid #e5e5e5;
                border-radius: 5px;
                padding: 0.875rem 0.6875rem;
                margin-bottom: 1rem;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
                text-decoration: none;

                &.active {
                  background-color: var(--theme-primary-color-light);
                  border: none;

                  .firstCol h4 {
                    font-weight: bold;
                  }
                }

                &:last-child {
                  margin-bottom: 0;
                }

                .firstCol {
                  h4 {
                    font-size: 0.8125rem;
                    line-height: 1.25rem;
                    margin-bottom: 0;
                    color: #4b4b4b;
                    font-weight: normal;
                  }

                  .time {
                    font-size: 0.6875rem;
                    line-height: 0.8rem;
                    color: #4b4b4b;

                    &.active {
                      color: var(--theme-primary-color);
                    }
                  }
                }

                .room {
                  display: block;
                  border-radius: 5px;
                  background-color: #f9f9fb;
                  font-size: 0.6875rem;
                  color: #8f8f8f;
                  padding: 0.25rem 0.5rem;
                  width: 38%;
                  margin-left: 0.3rem;

                  &.active {
                    background-color: var(--theme-primary-color);
                    color: #fff;
                  }
                }
              }
            }
          }
        }

        &.rooms {
          .btn {
            background-color: #f9f9fb;
            color: #8f8f8f;
            text-align: left;
            margin-bottom: 0.75rem;
            margin-left: 0.6875rem;
            margin-right: 0.6875rem;
            font-weight: normal;

            &.active {
              background-color: var(--theme-primary-color);
              color: #fff;
              font-weight: bold;
            }
          }
        }
      }
    }

    .curtainOverlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #000;
      opacity: 0.3;
      transition: opacity 0.3s ease-in-out;
      z-index: 11;

      &.folded {
        opacity: 0;
        pointer-events: none;
      }
    }

    .Central {
      background-color: #ffffff;
      border-radius: 10px;
      overflow: hidden;

      flex: 1;
      flex-direction: column;
      align-items: center;

      transform: translateX(20rem);
      opacity: 0.4;
      transition: all 0.3s ease-in-out;

      &.folded {
        opacity: 1;
        transform: translateX(0);
      }

      &.leftUnfolded {
        transform: translateX(-20rem);
        opacity: 0.4;
      }

      .embed-outer {
        margin: 0 auto;
        //max-width: 72%;

        .embed {
          width: 100%;
          padding-top: 22.25%; /* 1:1 Aspect Ratio */
          //padding-top: 56.25%; /* 1:1 Aspect Ratio */
          position: relative; /* If you want text inside of it */

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          &.llucana {
            padding-top: 56.25%;
          }
        }
      }

      .Content {
        padding-top: 20px;
        margin: 0 auto;
        max-width: 1200px;
      }
    }
  }

  .sidebar {
    width: 19.1rem;
    margin-left: 20px;
    display: flex;
    flex-direction: column;

    transform: translateX(20rem);
    opacity: 0.4;
    transition: all 0.3s ease-in-out;

    @include media-breakpoint-down(md) {
      margin-left: 0;
      margin-top: 1rem;
    }

    &.folded {
      opacity: 1;
      transform: translateX(0);
    }

    &.leftUnfolded {
      transform: translateX(-20rem) !important;
      opacity: 0.4 !important;
    }

    @include media-breakpoint-down(lg) {
      width: 17.8rem;
    }

    @include media-breakpoint-up(xl) {
      width: 21.2rem;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      align-self: stretch;
    }

    .logo {
      height: 9.25rem;
      padding: 0.7rem 1.3rem;
      background-origin: content-box;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      image-rendering: -webkit-optimize-contrast;
      border-radius: 10px;
    }

    h3 {
      color: #4b4b4b;
      font-size: 1rem;
      line-height: 1rem;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .marginated {
      margin-top: 15px;
      padding: 22px;
      border-radius: 10px;
      background-color: #ffffff;
    }

    .title {
      margin-bottom: 25px;
      display: flex;
      flex-direction: row;
      align-items: center;

      @include media-breakpoint-down(lg) {
        margin-bottom: 13px;
      }

      span::before {
        color: var(--theme-primary-color);
        font-size: 1.5rem;
        margin-right: 0.4rem;
      }

      h3 {
        margin-bottom: 0;
      }
    }

    .questions-wrap {
      .no-questions {
        padding: 1.5rem 0.6rem 1.3rem 0;
        border: 1px solid #e7e7e7;
        border-radius: 5px;
        margin-bottom: 1.68rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-down(lg) {
          margin-bottom: 13px;
        }

        h4 {
          font-size: 0.9rem;
          line-height: 1.3125rem;
          font-weight: bold;
          color: var(--theme-primary-color);
          margin-bottom: 1.25rem;
        }

        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;
          display: flex;
          flex-direction: column;

          li {
            display: flex;
            flex-direction: row;
            margin-bottom: 0.5rem;

            .icon {
              background-color: var(--theme-primary-color-light);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 1.375rem;
              height: 1.375rem;
              border-radius: 50%;
              margin-right: 0.625rem;
              font-size: 0.9rem;

              &::before {
                color: var(--theme-primary-color);
              }
            }
            .text {
              display: flex;
              flex: 1;
              font-size: 0.8rem;
              color: #4b4b4b;
            }
          }
        }
      }

      .questions {
        list-style-type: none;
        padding: 0;
        margin: 0;
        margin-top: -10px;
        display: flex;
        flex-direction: column-reverse;
        height: 12rem;
        overflow-y: scroll;
        -ms-overflow-style: none;
        mask-image: -webkit-gradient(
          linear,
          left 20%,
          left top,
          from(rgba(0, 0, 0, 1)),
          to(rgba(0, 0, 0, 0))
        );

        @include media-breakpoint-down(lg) {
          margin-top: -5px;
        }

        &::-webkit-scrollbar {
          display: none;
        }

        .question {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 15px;

          .thumb {
            flex-shrink: 0;
            width: 22px;
            height: 22px;
            border-radius: 11px;
            margin-right: 10px;
            background-image: url("./avatar.svg");
            background-size: cover;
            display: inline-block;
          }
          .basic-thumb {
            width: 22px;
            height: 22px;
            border-radius: 11px;
            margin-right: 10px;
            font-size: 0.55rem;
          }

          .content {
            font-size: 0.8125rem;
            line-height: 1.25rem;
            margin-top: -0.1rem;
            color: #4b4b4b;
          }

          @media not all and (min-resolution: 0.001dpcm) {
            display: block;

            @supports (-webkit-appearance: none) {
              .thumb {
                display: inline-block;
                position: relative;
                top: 0.3rem;
              }
              .content {
                display: inline;
              }
            }
          }
        }
      }

      .questions-input-wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        @include media-breakpoint-down(lg) {
          margin-top: 5px;
        }

        .questions-input {
          margin: 14px 16px 1px 16px;
          border: none;
          border-bottom: 1px solid #e5e5e5;

          font-size: 0.8125rem;
          font-weight: bold;
          color: #555555;
          padding-bottom: 8px;

          @include media-breakpoint-down(lg) {
            margin: 8px 14px 1px 14px;
          }

          &:focus,
          &.active {
            outline: none;
            border-bottom: 2px solid var(--theme-primary-color);
            margin-bottom: 0px;
          }
        }

        .questions-input-footer {
          margin: 8px 16px 5px 16px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          @include media-breakpoint-down(lg) {
            margin: 4px 12px 5px 12px;
          }

          span {
            font-size: 0.8125rem;
            line-height: 1rem;
            color: #8f8f8f;
            padding-top: 0.16rem;
          }

          button {
            margin-left: 6px;
            padding: 0;
            border: none;
            background: none;

            &:focus {
              outline: none;
            }

            .icon:before {
              color: #8f8f8f;
              font-size: 1rem;
            }

            &.active .icon:before {
              color: var(--theme-primary-color);
            }
          }
        }
      }
    }

    .survey-blocks-wrap {
      .no-surveys {
        padding: 1.56rem;
        border: 1px solid #e7e7e7;
        border-radius: 5px;
        margin-bottom: 1.68rem;

        p {
          color: #8f8f8f;
          font-size: 0.8125rem;
          text-align: center;
          margin: 0;
        }
      }

      .survey-block {
        padding: 16px;
        width: 100%;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;

        h4 {
          font-size: 0.875rem;
          line-height: 1.1875rem;
          font-weight: bold;
          color: #4b4b4b;
          margin-bottom: 1rem;
        }

        &.voted {
          padding: 0.625rem 1rem;
          border: 1px solid #e7e7e7;
          border-radius: 5px;

          h4 {
            font-size: 0.6875rem;
            line-height: 1rem;
            font-weight: normal;
            color: #878788;
            margin-bottom: 5px;
          }
          p {
            margin: 0;
            font-size: 0.6875rem;
            line-height: 1.3125rem;
            font-weight: normal;
            color: #878788;
            span {
              color: #cdcdcd;
            }
          }
        }

        .survey-options-wrap {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          .survey-options {
            list-style-type: none;
            padding: 0;
            margin: 0;

            .option {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              margin-bottom: 10px;

              &.disabled label {
                color: #e5e5e5;
              }

              label {
                font-size: 0.8125rem;
                line-height: 1.3125rem;
                color: #4b4b4b;
                margin: 0;
              }
              input {
                margin-right: 8px;
              }
            }
          }
          button {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
